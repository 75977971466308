import React, { useEffect, useState } from "react";
import AdminLeave from "./component/AdminLeave";
import { LeaveDetails } from "../Services/ApiCalling";
import { getEmployeeStatsData } from "../Services/ApiCalling";



const AdminLeaveView = ()=> {
  const [leaveUsers, setLeaveUsers] = useState();
  const token = localStorage.getItem("LogIn_Token") 

  const [status, setStatus] = useState()

  const getLeaveData = async (token) => {

    const res = await LeaveDetails(token)
    setLeaveUsers(res.data)

    // const res2 = await getEmployeeStatsData(token)
    // setStatus(res2.data);
  }
 
  useEffect(()=>{
    getLeaveData(token);
  
  },[])
  
  return (
    <>
      {leaveUsers && <AdminLeave leaveUsers={leaveUsers} getLeaveData={getLeaveData} emp_sts={status} />}
    </>
  );
}

export default AdminLeaveView;
