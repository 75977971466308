import { useEffect, useState } from "react";
import AllEmployee from "./component/allEmpCards";
import { getAllEmployeeData } from "../Services/ApiCalling";

const AllEmployeeView2 = ()=>{
    const [employeeData,setEmployeeData] = useState()
  const token = localStorage.getItem("LogIn_Token") 

    const fetchEmployee = async (token)=>{
     const resp = await getAllEmployeeData(token)
     console.log("respresp",resp)
     setEmployeeData(resp.data)
    }
    useEffect(()=>{
        fetchEmployee(token)
    },[])
    return(
        <>
        {employeeData && employeeData.length > 0  &&<AllEmployee employeeData={employeeData}/>}

        </>
    )
}

export default AllEmployeeView2;