import Axios from "axios";
// const base_Url = "https://15.207.162.165/oms";
const base_Url = "http://localhost:8089";
// const base_Url = "https://stage-oms.thesaicomputers.com/oms";
// const base_Url = "http://162.147.132.205:8089";


export function sigUp(values) {
  let body = {
    EMP_EMAIL_ID: values.email,
    EMP_PASS: values.password,
    CONF_PASS: values.confirmPassword
    // DESG: values.designation,
  };
  return new Promise((res, rej) => {
    Axios.post(`${base_Url}/emp-user/register`, body)
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function login(values) {
  let body = {
    EMP_EMAIL_ID: values.email,
    EMP_PASS: values.password,
  };
  return new Promise((res, rej) => {
    Axios.post(`${base_Url}/emp-user/login`, body)
      .then((response) => {
        res(response.data);
        console.log("response on loginnn", response);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}
