import React, { useEffect, useState } from "react";
import Select from "react-select";
import { getEmployeeDataByID, getEmployeeStatsData } from "../../Services/ApiCalling";


const ApplicationModal = ({ reqState, setStatus, setRemarks, setId, handleSubmit, leave_status }) => {

  console.log("locationlocation", reqState)
const [statdata,setStatData] = useState()

  const token = localStorage.getItem("LogIn_Token") 

  const statusOptions = [
    { value: "APPROVED", label: "Approved" },
    { value: "REJECT", label: "Rejected" },
  ];

  const getStatsForLeave = async(reqState)=>{
    if(reqState){

      const statdata = await getEmployeeStatsData(reqState.EMP_ID)
      setStatData(statdata.data)
    }
  }

  console.log("statdata",statdata)

useEffect(()=>{
  getStatsForLeave(reqState)
},[reqState])

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };


  console.log("statdata?.TOTAL_LEAVE statdata?.TOTAL_LEAVE",statdata?.TOTAL_LEAVE);

  return (
    <div id="test" className="modal custom-modal fade" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Application Status</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
              <div style={{display:"flex"}}>
                <h5>Total Leave</h5><h2 style={{marginLeft:"100px"}}>{statdata?.TOTAL_LEAVE}</h2>
              </div>
              <div style={{display:"flex"}}>
              <h5>Remaining Leave</h5><h2 style={{marginLeft:"60px"}}>{statdata?.REM_LEAVE}</h2>
              </div>
              <div style={{display:"flex"}}>
              <h5>Pending Leave</h5><h2 style={{marginLeft:"75px"}}>{statdata?.pendingRequestsCount}</h2>
              </div>
            <form>
              <div className="input-block mb-3">
                <label className="col-form-label">
                  Status <span className="text-danger">*</span>
                </label>
                <Select
                  options={statusOptions}
                  placeholder="Select"
                  styles={customStyles}
                  onChange={(e) => {
                    console.log("selected", e.value)
                    setStatus(e.value)
                  }}
                />
              </div>
              <div className="input-block mb-3">
                <label className="col-form-label">
                  Remarks <span className="text-danger">*</span>
                </label>
                <textarea
                  onChange={(e) => {
                    setRemarks(e.target.value)
                  }}
                  rows={4}
                  className="form-control"
                />
              </div>
              <div className="submit-section">
                <button
                  onClick={() => handleSubmit()}
                  className="btn btn-primary submit-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  type="reset"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApplicationModal;