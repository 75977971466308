import Axios from "axios";
// const base_Url = "https://stage-oms.thesaicomputers.com/oms";
const base_Url = "http://localhost/:8089";
//const base_Url = "http://162.147.132.205:8088";
// const base_Url = "http://162.147.132.205:8088";
// const base_Url2 = "http://162.147.132.205:8088";


export function Emp_Leave(token, obj_body) {
  let config = {
    headers: {
      Authorization: token,
    },
  };

  let body = {
    REQ_FROM: obj_body.date1,
    REQ_TO: obj_body.date2,
    REQ_TYPE: obj_body.REQ_TYPE,
    NO_DAYS: obj_body.days,
    REASON: obj_body.reason,
    HR_REMARK: " "
  }
  return new Promise((res, rej) => {
    Axios.post(`${base_Url}/request/insert-request-details`, body, config)
      .then((response) => {
        console.log("response", res);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}



export function getEmpData(token) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/request/get-request-data`, config)
      .then((response) => {
        console.log("response of gert emppp", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

// Get holidays data
export function getHolidaysData(token) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/emp-user/getHoliday-data`, config)
      .then((response) => {
        console.log("response of get holidays", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}



// Get all emoloyee data
export function getAllEmployeeData(token) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/admin/show-all-profile`, config)
      .then((response) => {
        console.log("response of get all employee data", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}
  
let EMP = localStorage.getItem("DecodedToken")
EMP = JSON.parse(EMP)

// Get emoloyee stats data
export function getEmployeeStatsData(EMP_ID) {

  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/request/leave-stats/${EMP_ID}`)
      .then((response) => {
        console.log("response of get emp stats", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}


export function getSectionData(currentPage, pageSize,col_name) {

  return new Promise((res, rej) => {
    // Axios.get(`http://localhost:8080/static/poc-for-portfolio-ageing/${SECTION_KEY}`)

    Axios.get(`https://stage-oms.thesaicomputers.com/oms/static/poc-for-portfolio?page=${currentPage}&pageSize=${pageSize}&col_name=${col_name}`)
      .then((response) => {
        console.log("response of get emp stats", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function getOneSectionData(keyScore) {

  return new Promise((res, rej) => {
    Axios.get(`https://stage-oms.thesaicomputers.com/oms/static/poc-for-portfolio-feeder-75/${keyScore}`)
      .then((response) => {
        console.log("response of get emp stats", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function getOneSectionDataBottleNeck(sectionKey) {

  return new Promise((res, rej) => {
    Axios.get(`https://stage-oms.thesaicomputers.com/oms/static/poc-for-portfolio-bottleneck/${sectionKey}`)
      .then((response) => {
        console.log("response of get emp stats", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function getOneSectionDataShifting(sectionKey) {

  return new Promise((res, rej) => {
    Axios.get(`https://stage-oms.thesaicomputers.com/oms/static/poc-for-portfolio-shifting/${sectionKey}`)
      .then((response) => {
        console.log("response of get emp stats", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function getOneSectionDataLoadShedding(FEEDERID) {

  return new Promise((res, rej) => {
    Axios.get(`https://stage-oms.thesaicomputers.com/oms/static/poc-for-portfolio-load-shedding/${FEEDERID}`)
      .then((response) => {
        console.log("response of get emp stats", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}


export function getOneSectionDataPilcRemoval(SECTION_KEY) {

  return new Promise((res, rej) => {
    Axios.get(`https://stage-oms.thesaicomputers.com/oms/static/poc-for-portfolio-pilc-removal/${SECTION_KEY}`)
      .then((response) => {
        console.log("response of get emp stats", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function getOneSectionDataCcc(SECTION_KEY) {

  return new Promise((res, rej) => {
    Axios.get(`https://stage-oms.thesaicomputers.com/oms/static/poc-for-portfolio-ccc/${SECTION_KEY}`)
      .then((response) => {
        console.log("response of get emp stats", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function getOneSectionDataRadialToRing(SECTION_KEY) {

  return new Promise((res, rej) => {
    Axios.get(`https://stage-oms.thesaicomputers.com/oms/static/poc-for-portfolio-radial-to-ring/${SECTION_KEY}`)
      .then((response) => {
        console.log("response of get emp stats", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function getOneSectionDataAgeging(SECTION_KEY) {

  return new Promise((res, rej) => {
    Axios.get(`https://stage-oms.thesaicomputers.com/oms/static/poc-for-portfolio-ageing/${SECTION_KEY}`)
      .then((response) => {
        console.log("response of get emp stats", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}



// Get admin specific profile
export function getSpecificProfile(token) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/admin/show-specific-profile`, config)
      .then((response) => {
        console.log("response of specific profile", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}


// Admin Leave Status
export function LeaveStatus(token, obj) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  let body = {
    ID: obj.ID,
    STATUS: obj.STATUS,
    REMARK: obj.REMARK
  }
  return new Promise((res, rej) => {
    Axios.post(`${base_Url}/request/update-approval-data`, body, config)
      .then((response) => {
        console.log("response", res);
        return res(response);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}


//Admin leave details
export function LeaveDetails(token) {
  let config = {
    headers: {
      Authorization: token
    }
  }
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/request/get-admin-request-data`, config)
      .then((response) => {
        console.log("response", res);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}


// export function getDesignations() {
//   return new Promise((res, rej) => {
//     Axios.get(`${base_Url}/static/get-designation`)
//       .then((response) => {
//         console.log("response", res);
//         return res(response.data);
//       })
//       .catch((error) => {
//         console.log("errror");
//       });
//   });
// }

export function getUserProfileData(token) {
  let config = {
    headers: {
      Authorization: token
    }
  }
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/emp-user/employee-profile`, config)
      .then((response) => {
        console.log("response", res);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function getUserAreaDeatils(token) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/user/get-user-profile-details`, config)
      .then((response) => {
        console.log("response", res);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}


export function getOneDesginationDetail(desg) {
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/static/get-desg-details/${desg}`)
      .then((response) => {
        console.log("response", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}


export function sendOtp(mobileNo) {
  console.log("mobb getOtp", mobileNo);
  return new Promise((res, rej) => {
    Axios.post(`${base_Url}/static/getOtp`, mobileNo).then((response) => {
      console.log("response", response.data);
      return res(response.data);
    });
  }).catch((error) => {
    console.log("error ", error);
  });
}

export function validateOtp(EMP_OTP_NUM,EMP_EMAIL_ID) {
  let body = {
    EMP_OTP_NUM: EMP_OTP_NUM,
    EMP_EMAIL_ID: EMP_EMAIL_ID,
  };
  console.log("validation dataaa", body);
  return new Promise((res, rej) => {
    Axios.post(`${base_Url}/emp-user/verify-otp`, body).then((response) => {
      console.log("response", response.data);
      return res(response.data);
    });
  }).catch((error) => {
    console.log("error", error);
  });
}
