import React, { useEffect, useState } from "react";
import axios from "axios";
import Charts from "./charts";
import Reports from "./Reports";
import Parameters from "./parameters";
import Statistics from "./statistics";
import InvoiceTable from "./invoiceTable";
import PaymentTable from "./paymentTable";
import ClientTable from "./clientTable";
import RecentTable from "./recentTable";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import { base_url } from "../../../../../base_urls";
import {
  getSectionData,
  getOneSectionData,
  getOneSectionDataBottleNeck,
  getOneSectionDataShifting,
  getOneSectionDataLoadShedding,
  getOneSectionDataPilcRemoval,
  getOneSectionDataCcc,
  getOneSectionDataRadialToRing,
  getOneSectionDataAgeging,
} from "../../../../Services/ApiCalling";
import HealthInput from "./healthInput";
import BottleNeck from "./bottleNeck";
import Shifting from "./shifting";
import LoadShedding from "./loadShedding";
import PilcRemoval from "./pilcRemoval";
import CurrentCarryingCapacity from "./ccc";
import RadialToRing from "./radialToRing";
import Ageging from "./ageging";
import RepeatedSchemes from "./repeatedSchemes";
import RedFlag from "./redFlag";

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100); // 100 documents per page
  const [totalRecords, setTotalRecords] = useState(0);
  const [secId, setSecId] = useState();
  const [secConfig, setSecConfig] = useState();
  const [col_name, setColName] = useState("FEEDER_75");
  const [noOfConsumers, setNoOfConsumers] = useState(0);
  const [secData, setSecData] = useState();
  const [feeder75, setFeeder75] = useState();
  const [bottleNeck, setBottleNeck] = useState();
  const [shifting, setShifting] = useState();
  const [loadShedding, setLoadSheddinf] = useState();
  const [pilcRemoval, setPilcRemoval] = useState();
  const [ccc, setCcc] = useState();
  const [radialTORing, setRadialToRing] = useState();
  const [ageing, setAgeing] = useState();

  const getSecData = async () => {
    try {
      setIsLoading(true);
      let dat = await getSectionData(currentPage, pageSize, col_name);
      console.log("at.dataat.data", dat.data);
      setUsers(dat.data);
      setNoOfConsumers(dat.affectedConsumers);
      setTotalRecords(dat.pagination.totalRecords);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
    setSecId();
    setSecConfig();
    setIsLoading(false);
  };

  const getSecDataForFedder75 = async () => {
    console.log("secDatasecDatasecData", secData);
    if (secData && col_name == "FEEDER_75") {
      const feederData75 = await getOneSectionData(secData?.SECTION_KEY);
      setFeeder75(feederData75.data);
      console.log("feederData75feederData75", feederData75);
    }
  };

  const getSecDataForBottleneck = async () => {
    console.log("secDatasecDatasecData", secData);
    if (secData && col_name == "BOTTLENECK") {
      const bottleNeck = await getOneSectionDataBottleNeck(
        secData?.SECTION_KEY
      );
      setBottleNeck(bottleNeck.data);
      //  console.log("feederData75feederData75 inside bottleneck",feederData75)
    }
  };

  const getSecDataForshifting = async () => {
    console.log("secDatasecDatasecData", secData);
    if (secData && col_name == "SHIFTING") {
      const bottleNeck = await getOneSectionDataShifting(secData?.SECTION_KEY);
      setShifting(bottleNeck.data);
      //  console.log("feederData75feederData75 inside bottleneck",feederData75)
    }
  };

  const getSecDataForLoadShedding = async () => {
    console.log("secDatasecDatasecData", secData);
    if (secData && col_name == "LOAD_SHEDDING") {
      const bottleNeck = await getOneSectionDataLoadShedding(secData?.FEEDERID);
      setLoadSheddinf(bottleNeck.data);
      //  console.log("feederData75feederData75 inside bottleneck",feederData75)
    }
  };

  const getSecDataForPilcRemoval = async () => {
    console.log("secDatasecDatasecData", secData);
    if (secData && col_name == "PILC_REMOVAL") {
      const bottleNeck = await getOneSectionDataPilcRemoval(
        secData?.SECTION_KEY
      );
      setPilcRemoval(bottleNeck.data);
      //  console.log("feederData75feederData75 inside bottleneck",feederData75)
    }
  };

  const getSecDataForPilcCcc = async () => {
    console.log("secDatasecDatasecData", secData);
    if (secData && col_name == "CCC") {
      const bottleNeck = await getOneSectionDataCcc(secData?.SECTION_KEY);
      setCcc(bottleNeck.data);
      //  console.log("feederData75feederData75 inside bottleneck",feederData75)
    }
  };

  const getSecDataForRadialToRing = async () => {
    console.log("secDatasecDatasecData", secData);
    if (secData && col_name == "RADIAL_TO_RING") {
      const bottleNeck = await getOneSectionDataRadialToRing(
        secData?.SECTION_KEY
      );
      setRadialToRing(bottleNeck.data);
      //  console.log("feederData75feederData75 inside bottleneck",feederData75)
    }
  };

  const getSecDataForAgeging = async () => {
    console.log("secDatasecDatasecData", secData);
    if (secData && col_name == "AGEING") {
      const bottleNeck = await getOneSectionDataAgeging(secData?.SECTION_KEY);
      setAgeing(bottleNeck.data);
      //  console.log("feederData75feederData75 inside bottleneck",feederData75)
    }
  };

  useEffect(() => {
    getSecData();
  }, [currentPage, pageSize, col_name]);

  console.log("feederData75feederData75", col_name);

  useEffect(() => {
    getSecDataForFedder75();
    getSecDataForBottleneck();
    getSecDataForshifting();
    getSecDataForLoadShedding();
    getSecDataForPilcRemoval();
    getSecDataForPilcCcc();
    getSecDataForRadialToRing();
    getSecDataForAgeging();
  }, [secData]);

  return (
    <div className="main-wrapper">
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs maintitle="Welcome Prateek" title="Portfolio Management Dashboard" />
          {/* /Page Header */}
          <div className="row">
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3" key={"1"}>
              <div className="card dash-widget">
                <div className="card-body">
                  {/* <span ></span> */}
                  <div
                    className="dash-widget-info"
                    style={{ textAlign: "left" }}
                  >
                    <h3>{"Andheri"}</h3>
                    <span>{"Division"}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3" key={"1"}>
              <div className="card dash-widget">
                <div className="card-body">
                  {/* <span className={`dash-widget-icon fa fa-cubes}`} /> */}
                  <div
                    className="dash-widget-info"
                    style={{ textAlign: "left" }}
                  >
                    <h3>{"2134"}</h3>
                    <span>{"No of Sections"}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3" key={"1"}>
              <div className="card dash-widget">
                <div className="card-body">
                  {/* <span className={`dash-widget-icon fa fa-cubes}`} /> */}
                  <div
                    className="dash-widget-info"
                    style={{ textAlign: "left" }}
                  >
                    <h3>{"1345"}</h3>
                    <span>{"Improvement Section Area"}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3" key={"1"}>
              <div className="card dash-widget">
                <div className="card-body">
                  {/* <span className={`dash-widget-icon fa fa-cubes}`} /> */}
                  <div
                    className="dash-widget-info"
                    style={{ textAlign: "left" }}
                  >
                   {!isLoading ? <h3>{noOfConsumers}</h3> :
                    <p className="placeholder-glow">
                    <span className="placeholder col-12" />
                  </p>
                  }
                    <span>{"Consumers Affected"}</span>
                  </div>
                </div>
              </div>
              {/* <div className="progress mb-2" style={{ height: "5px" }}>
                      <div
                        className="progress-bar bg-primary"
                        role="progressbar"
                        style={{ width: "70%" }}
                        aria-valuenow={40}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                      </div> */}
            </div>
          </div>
          {/* /Charts */}
          {/* <Charts /> */}
          {/* /Charts */}
          <Reports setColName={setColName} setSecId={setSecId} />
          {/* <Parameters setColName={setColName} setSecId={setSecId}/> */}
          <HealthInput
            users={users}
            setIsLoading={setIsLoading}
            setUsers={setUsers}
          />
          {/* <Statistics /> */}
          <div className="row">
            {/* <InvoiceTable /> */}
            {
              <PaymentTable
                users={users}
                setSecId={setSecId}
                col_name={col_name}
                currentPage={currentPage}
                setPageSize={setPageSize}
                totalRecords={totalRecords}
                setSecConfig={setSecConfig}
                setSecData={setSecData}
                isLoading={isLoading}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
              
              />
            }
          </div>
          {secId && (
            <div className="row">
              {col_name === "FEEDER_75" && (
                <ClientTable
                  secId={secId}
                  secConfig={secConfig}
                  feeder75={feeder75}
                  secData={secData}
                />
              )}
              {col_name === "BOTTLENECK" && (
                <BottleNeck
                  secId={secId}
                  secConfig={secConfig}
                  bottleNeck={bottleNeck}
                  secData={secData}

                />
              )}
              {col_name === "SHIFTING" && (
                <Shifting
                  secId={secId}
                  secConfig={secConfig}
                  shifting={shifting}
                  secData={secData}
                />
              )}
              {col_name === "LOAD_SHEDDING" && (
                <LoadShedding
                  secId={secId}
                  secConfig={secConfig}
                  loadShedding={loadShedding}
                  secData={secData}
                />
              )}
              {col_name === "PILC_REMOVAL" && (
                <PilcRemoval
                  secId={secId}
                  secConfig={secConfig}
                  pilcRemoval={pilcRemoval}
                  secData={secData}
                />
              )}
              {col_name === "CCC" && (
                <CurrentCarryingCapacity
                  secId={secId}
                  secConfig={secConfig}
                  ccc={ccc}
                  secData={secData}
                />
              )}
              {col_name === "RADIAL_TO_RING" && (
                <RadialToRing
                  secId={secId}
                  secConfig={secConfig}
                  radialTORing={radialTORing}
                  secData={secData}
                />
              )}
              {col_name === "AGEING" && (
                <Ageging
                  secId={secId}
                  secConfig={secConfig}
                  ageing={ageing}
                  secData={secData}
                />
              )}
                {col_name === "REPEATED_SCHEMES" && (
                <RepeatedSchemes
                  secId={secId}
                  secConfig={secConfig}
                  // ageing={ageing}
                  secData={secData}
                />
              )}

{col_name ==="RED_FLAG" && (
                <RedFlag
                  secId={secId}
                  secConfig={secConfig}
                  // ageing={ageing}
                  secData={secData}
                />
              )}

              {/* <RecentTable /> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
