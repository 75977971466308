import { useState } from "react";

const HealthInput = ({users,setIsLoading,setUsers}) => {

    const [formValues, setFormValues] = useState({
        feeder_75: 10,  // Default value for FEEDER > 75
        load_shedding: 5, // Default value for LOAD SHEDDING,
        bottleneck:10,
        ageing:5,
        ccc:10,
        repeated_schemes:10,
        red_flag:15,
        radial_to_ring:15,
        shifting:10,
        pilc_removal:10
      });

    const handleChangeInput = (e)=>{
        const { name, value } = e.target;
        setFormValues({
          ...formValues,
          [name]: Number(value)
        });
    }
  let newArr = []
    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent form refresh
        const totalSum = Object.values(formValues).reduce((acc, curr) => acc + curr, 0);  // Calculate the total sum
        console.log("totalSumtotalSum",totalSum)
        if (totalSum > 100) {
          alert('Cannot cross the weightage of 100');
        } else {
            setIsLoading(true)
            users.map((item)=>{
            let weightedSum = (item.FEEDER_75 * formValues.feeder_75) + (item.LOAD_SHEDDING * formValues.load_shedding) + (item.BOTTLENECK * formValues.bottleneck) +
            (item.AGEING * formValues.ageing) + (item.CCC * formValues.ccc) + (item.REPEATED_SCHEMES * formValues.repeated_schemes) + (item.RED_FLAG * formValues.red_flag) + 
            (item.RADIAL_TO_RING * formValues.radial_to_ring) + (item.SHIFTING * formValues.shifting) + (item.PILC_REMOVAL * formValues.pilc_removal)
              newArr.push({
                ...item, NEW_CRITICAL_LEVEL: weightedSum, NEW_HEALTH_SCORE : (100-weightedSum)
              })
            })
            console.log(newArr.length,newArr,users.length)
            setUsers(newArr)
            setIsLoading(false)
          // You can add form submission logic here (e.g., send data to server)
        }
      };                                                                                                                                                                                                                                                                                        

    return (
        <>
 
            <div className="row">
            <h3 className="card-title mb-0">CHANGE WEIGHTAGE  (if required) </h3>

                <form action="#" onSubmit={handleSubmit}>
                    <div style={{display:"flex"}}>
                        <div>
                            <label className="col-lg-12 col-form-label">{"FEEDER > 75"}</label>
                            <div className="input-block mb-3 row">
                                <div className="col-lg-9">
                                    <input name="feeder_75" type="number" className="form-control" value={formValues.feeder_75} onChange={handleChangeInput} />
                                </div>
                            </div>
                        </div>
                        <div>

                            <label className="col-lg-12 col-form-label">{"LOAD SHEDDING"}</label>
                            <div className="input-block mb-3 row">
                                <div className="col-lg-9">
                                    <input name="load_shedding" type="number" value={formValues.load_shedding}  className="form-control" onChange={handleChangeInput}/>
                                </div>
                            </div>
                        </div>
                        <div>

                            <label className="col-lg-12 col-form-label">BOTTLENECK</label>
                            <div className="input-block mb-3 row">
                                <div className="col-lg-9">
                                    <input name="bottleneck" type="number" value={formValues.bottleneck} className="form-control" onChange={handleChangeInput} />
                                </div>
                            </div>
                        </div>
                        <div>

                            <label className="col-lg-12 col-form-label">AGEING</label>
                        <div className="input-block mb-3 row">
                            <div className="col-lg-9">
                                <input name="ageing" type="number" value={formValues.ageing} className="form-control" onChange={handleChangeInput}/>
                            </div>
                        </div>
                        </div>
                        <div>

                            <label className="col-lg-12 col-form-label">CCC</label>
                        <div className="input-block mb-3 row">
                            <div className="col-lg-9">
                                <input name="ccc" type="number" value={formValues.ccc} className="form-control" onChange={handleChangeInput}/>
                            </div>
                        </div>
                        </div>
                        <div>

                        <label className="col-lg-12 col-form-label">REPEATED SCHEMES</label>
                        <div className="input-block mb-3 row">
                            <div className="col-lg-9">
                                <input name="repeated_schemes" value={formValues.repeated_schemes} type="number" className="form-control" onChange={handleChangeInput}/>
                            </div>
                        </div>
                        
                        </div>
                        <div>

                        <label className="col-lg-12 col-form-label">RED FLAG</label>
                        <div className="input-block mb-3 row">
                            <div className="col-lg-9">
                                <input name="red_flag"  value={formValues.red_flag} type="number" className="form-control" onChange={handleChangeInput} />
                            </div>
                        </div>
                        
                        </div>
                        <div>

                        <label className="col-lg-12 col-form-label">RADIAL TO RING</label>
                        <div className="input-block mb-3 row">
                            <div className="col-lg-9">
                                <input name="radial_to_ring" value={formValues.radial_to_ring} type="number" className="form-control" onChange={handleChangeInput}/>
                            </div>
                        </div>
                        
                        </div>
                        <div>

                        <label className="col-lg-12 col-form-label">SHIFTING</label>
                        <div className="input-block mb-3 row">
                            <div className="col-lg-9">
                                <input name="shifting" value={formValues.shifting} type="number" className="form-control" onChange={handleChangeInput}/>
                            </div>
                        </div>
                        
                        </div>
                        <div>

                        <label className="col-lg-12 col-form-label">PILC REMOVAL</label>
                                            <div className="input-block mb-3 row">
                                                <div className="col-lg-9">
                                                    <input name="pilc_removal"  value={formValues.pilc_removal} type="number" className="form-control" onChange={handleChangeInput}/>
                                                </div>
                                            </div>
                        
                        </div>
                    <div className="text-end my-auto">
                        <button type="submit" className="btn btn-primary">
                            Submit
                        </button>
                    </div>
                    </div>
                </form>
            </div>
            {/* </div>
          </div>
        </div> */}
        </>
    )
}

export default HealthInput