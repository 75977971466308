import { Table } from "antd";

import { useEffect } from "react";

const RadialToRing = ({secId,secConfig,radialTORing,secData}) => {


console.log("secDatasecData",secData)
  useEffect(()=>{

  },[])

 
  let issueArr = [secData]

  let newArr = [{...radialTORing,...secData}]

  const columns = [
    {
      title: "SECTION",
      dataIndex: "ID",
      // sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "COMMISSION DATE",
      dataIndex: "COMMISSION_DATE",
      render: (text) => {
        // Convert the string to a Date object
        const date = new Date(text);
        // Format the date to "YYYY-MM-DD"
        const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
        return formattedDate;
      },
      // sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "FEEDER-ID",
      dataIndex: "FEEDERID",
      // sorter: (a, b) => a.email.length - b.email.length,
    },
    {
        title: "AGE",
        dataIndex: "AGEING",
      
        // sorter: (a, b) => a.status.length - b.status.length,
      },
      {
        title: "CONSUMER CONNECTED",
        dataIndex: "NO_OF_CONSUMERS",
        // sorter: true,
   
      },
    {
      title: "No. of Cables",
      dataIndex: "NUMBEROFCO",
    
      // sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Total Length",
      dataIndex: "CABLESIZE",
      // sorter: true,
 
    },
    {
        title: "No. of Joints",
        dataIndex: "FCABLESIZE_MAX",
        // sorter: true,
   
      },
      {
        title: "TOTAL COST (METER)",
        dataIndex: "Total_Cost_Meter",
        // sorter: true,
   
      },
      {
        title: "Cable COST (METER)",
        dataIndex: "Cable_Cost_Meter",
        // sorter: true,
   
      },
      {
        title: "Total Investment",
        dataIndex: "Total_Investment",
        // sorter: true,
   
      
      }
  ];

  const columnsIssues = [
    {
      title: "FEEDER > 75 %",
      dataIndex: "FEEDER_75",
      render: (text) => {
        let isIssue = text == 1 ? "YES" : "NO"
        return isIssue;
      },
      // sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "LOAD SHEDDING",
      dataIndex: "LOAD_SHEDDING",
      render: (text) => {
        let isIssue = text == 1 ? "YES" : "NO"
        return isIssue;
      },
      // sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "BOTTLENECK",
      dataIndex: "BOTTLENECK",
      render: (text) => {
        let isIssue = text == 1 ? "YES" : "NO"
        return isIssue;
      },
      // sorter: (a, b) => a.email.length - b.email.length,
    },
  
    {
      title: "AGEING",
      dataIndex: "AGEING",
      render: (text) => {
        let isIssue = text == 1 ? "YES" : "NO"
        return isIssue;
      },
      // sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "CCC",
      dataIndex: "CCC",
      render: (text) => {
        let isIssue = text == 1 ? "YES" : "NO"
        return isIssue;
      },
      // sorter: true,
 
    },
    {
        title: "REPEATED SCHEMES",
        dataIndex: "REPEATED_SCHEMES",
        render: (text) => {
        
          let isIssue = text == 1 ? "YES" : "NO"
          return isIssue;
        },
        // sorter: true,
   
      },
      {
        title: "RED FLAG",
        dataIndex: "RED_FLAG",
        render: (text) => {
        
          let isIssue = text == 1 ? "YES" : "NO"
          return isIssue;
        },
        // sorter: true,
   
      },
      {
        title: "RADIAL TO RING",
        dataIndex: "RADIAL_TO_RING",
        render: (text) => {
        
          let isIssue = text == 1 ? "YES" : "NO"
          return isIssue;
        },
        // sorter: true,
   
      },
      {
        title: "SHIFTING",
        dataIndex: "SHIFTING",
        // sorter: true,
        render: (text) => {
        
          let isIssue = text == 1 ? "YES" : "NO"
          return isIssue;
        },
   
      },
      {
        title: "PILC REMOVAL",
        dataIndex: "PILC_REMOVAL",
        render: (text) => {
        
          let isIssue = text == 1 ? "YES" : "NO"
          return isIssue;
        },
        // sorter: true,
   
      }
  ];

  return (
    <>
    <div className="col-md-12">
      <div className="card card-table flex-fill">
        <div className="card-header">
          <h3 className="card-title mb-0">SINGLE LINE DIAGRAM SECTION</h3>
        </div>
        <div className="card-body flex">
          <div className="table-responsive">
          <div className="row">
          <div  className="col-md-5">
          <img src={`https://tripura.saielectricity.com/sld/${secId}.png`} 
          onDoubleClick={() => window.open(`https://tripura.saielectricity.com/sld/${secId}.png`, '_blank')}
          style={{height:"350px"}}/>
          </div>
          <div  className="col-md-7  overflow-x-auto" style={{textAlign:"center"}}>
         { radialTORing &&
         <>
         <Table
              dataSource={newArr?.length > 0 ? newArr : []}
              columns={columns}
              pagination={false}
              rowKey={(record) => record.id}
            />
             <h3 className="card-title " style={{textAlign:"justify" ,marginTop:"50px"}}>ISSUES FOUND</h3>
             
             <Table
              dataSource={issueArr?.length > 0 ? issueArr : []}
              columns={columnsIssues}
              pagination={false}
              rowKey={(record) => record.id}
            />
         </>
            }
          </div>
       
         
  </div>
           
         
          </div>
        </div>
      </div>
    </div>
 
    </>
  );
};

export default RadialToRing;
