import React, { useEffect, useState } from "react";
import { getHolidaysData } from "../Services/ApiCalling";
import Holidays from "./component/holiday";

function HolidaysView() {
  const [holidaysData, setHolidaysData] = useState()

  const token = localStorage.getItem("LogIn_Token") 

  const HolidayData = async (token) => {
    const res = await getHolidaysData(token)
    setHolidaysData(res)
    console.log('resppp', res);
  }
  useEffect(() => {
    HolidayData(token);
  }, []);
  return (
    <>
      {holidaysData && <Holidays holidaysData={holidaysData} />}
    </>
  );
}

export default HolidaysView;
