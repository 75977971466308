import React, { useState } from "react";
import Select, { NonceProvider } from "react-select";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { RiDeleteBin2Line } from "react-icons/ri";
import { Emp_Leave } from "../../Services/ApiCalling"
import * as Yup from "yup";
import moment from "moment"

const EmployeeWFHModelPopup = ({empData,requestType}) => {

  const [taskField, setTaskField] = useState([{taskName:"",hours:""}])
  const [days, setDays] = useState(0)
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState(null);
  const [reason, setReason] = useState('');

  const token =  localStorage.getItem("LogIn_Token")
  const minDate1 = new Date();
  const minDate2 = new Date(selectedDate1);

  const resetValue = ()=>{
    setSelectedDate1(null);
    setSelectedDate2(null);
    setReason('');
    setDays(0);
  }
  const checkSubmit = ()=>{
    if(selectedDate1 === null ){
      if(selectedDate2 === null){
        if(reason.length <=0 ){
          return true
        }
      }
    }else if(selectedDate2 === null){
      if(reason.length <=0){
        return true
      }else{
        return true
      }
    }else if(reason.length <=0){
      return true
    }else{
      return false
    }
  }

  const handleSubmit =async (e) =>{
  e.preventDefault()
  
    const obj={
      date1: selectedDate1,
      date2: selectedDate2,
      REQ_TYPE: requestType,
      days:  days,
      reason: reason
    }
    resetValue();
     await  Emp_Leave(token, obj);
     await  empData(token);
    
  }

  const sunday1 = (selectedDate1)=>{
    const day = selectedDate1.getDay();
    return(day !== 0);
  }
  
  const sunday2 = (selectedDate2)=>{
    const day = selectedDate2.getDay();
    return(day !== 0);
  }

  const handleDateChange1 = (selectedDate1) => {
      setSelectedDate1(selectedDate1);
      // console.log("length of state : ",reason.length);
  };

  const handleDateChange2 = (selectedDate2) => {
      if(selectedDate1 !== null && selectedDate1 <= selectedDate2){
        // setSelectedDate2(selectedDate2);
//////////////////////////////////////////////
        const dt_arr = dateRange(selectedDate1, selectedDate2);
      const dayNo = []
       dt_arr.map((item, ind) =>{
        dayNo.push(item.getDay())
      })
      const getSunday=[]
      dayNo.map((sun, i)=>{
          if(sun === 0){
            getSunday.push(sun)
          }
      })
/////////////////////////////////////////////
        let yy = ((selectedDate2 - selectedDate1)/(1000*60*60*24))+1
        let xx = yy - getSunday.length
        setDays(xx);
                
        const dt1 = moment(selectedDate1).format('YYYY-MM-DD');
        setSelectedDate1(dt1);
        const dt2 = moment(selectedDate2).format('YYYY-MM-DD');
        setSelectedDate2(dt2);
      }
      else{
        toast.error("Select From_Date first ");
      }
  };
  
   /////////////////////////////////////////
   function dateRange(startDate, endDate) {
    const dates = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        dates.push(new Date(currentDate)); // Clone the date object
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
}
  /////////////////////////////////////////


  function mouseOver(event) {
    event.target.style.background = "#FF9F43";
    event.target.style.color = "white";
  }
  function mouseOut(event) {
    event.target.style.background = "white";
    event.target.style.color = "#FF9F43";
  }

  const handleTaskChange=(e, i)=>{
    const {name, value} = e.target
    const handleInput = [...taskField]
    handleInput[i][name]=value
    setTaskField(handleInput)
  }
  const deleteTaskField=(i)=>{
    const del = [...taskField]
    del.splice(i,1);
    setTaskField(del);
  }
  
 

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  return (
    <>
      <div id="add_wfh" className="modal custom-modal fade" role="dialog" > 
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content" >
            <div className="modal-header">
              <h5 className="modal-title">Add WFH</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={resetValue}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>

                {/* <div className="input-block mb-3">
                  <label className="col-form-label">
                    WFM Type <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={Wfmtype}
                    placeholder="Select"
                    defaultValue={""}
                    onChange={(e)=>{setSelectOpt(e.label);}}
                  />
                </div> */}

                <div className="input-block mb-3">
                  <label className="col-form-label">
                    From <span className="text-danger">*</span>
                  </label>
                  <div className="cal-icon">
                    <DatePicker
                      selected={selectedDate1}
                      onChange={handleDateChange1}
                      className="form-control datetimepicker"
                      type="date"
                      dateFormat="dd-MM-yyyy"
                      minDate={minDate1}
                      filterDate={sunday1}
                      onKeyDown={(e) => {
                        e.preventDefault();
                    }}
                    />
                    
                  </div>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    To <span className="text-danger">*</span>
                  </label>
                  <div className="cal-icon">
                    <DatePicker
                      selected={selectedDate2}
                      onChange={handleDateChange2}
                      className="form-control datetimepicker"
                      type="date"
                      dateFormat="dd-MM-yyyy"
                      minDate={minDate2}
                      filterDate={sunday2}
                      onKeyDown={(e) => {
                        e.preventDefault();
                    }}
                    />
                  </div>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Number of days <span className="text-danger">*</span>
                  </label>
                  <input className="form-control" readOnly type="text" placeholder={days} />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Remaining WFH <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    readOnly
                    defaultValue={12}
                    type="text"
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    WFH Reason <span className="text-danger">*</span>
                  </label>
                  <textarea
                    rows={2}
                    className="form-control"
                    value={reason}
                    onChange={(e)=>{setReason(e.target.value);}}
                  />
                </div>
                  {/* "Add_task code begin" */}
                  <div style={{width:"75px", color:"#FF9F43"}}>  
                    <Link
                      to="#"
                      className="btn dropdown-item rounded-2"
                      onMouseOver={mouseOver} onMouseOut={mouseOut}
                      onClick={()=>{setTaskField([...taskField, {taskName:"",hours:""}]) }}
                    >
                      {"Add Task"}
                    </Link>
                  </div>

                  {
                    taskField.map((item, i)=>{
                      console.log(taskField)
                      return(
                        <>
                          <div style={{display:"flex", border:"1px solid #D3D3D3", borderRadius:"3px", marginBottom:"3px"}}>
                            <textarea
                              style={{width:"350px", border:"0px solid white"}}
                              rows={1}
                              className="form-control"
                              placeholder="Task/SubTask"  
                              name="taskName"
                              value={item.taskName}
                              onChange={(e)=>handleTaskChange(e,i)}
                            />
                              <input
                                style={{width:"80px", border:"0px solid white"}}
                                className="form-control"
                                placeholder="Hours"
                                type="number"
                                name="hours"
                                value={item.hours}
                                onChange={(e)=>handleTaskChange(e,i)}
                              />
                              <button type="button" onClick={()=>deleteTaskField(i)} style={{color:"#FF9F43",backgroundColor:"white", fontSize:"x-large", border:"0px white"}} disabled={taskField.length===1}>
                                <RiDeleteBin2Line />
                              </button>
                          </div>
                        </>
                      )
                    })
                  }  

                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    type="submit"
                   disabled={checkSubmit()}
                  >
                    Submit
                  </button>
                  <h6  style={checkSubmit() === false ?{display:'none'}:{display:"block"}}
                  >Fill out all the fields before submition
                  </h6>
                </div>
              </form>
              
            </div>
          </div>
        </div>
      </div>


      {/* <div id="edit_wfm" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit WFM</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    WFM Type <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={Wfmtype}
                    placeholder="Select"
                    styles={customStyles}
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    From <span className="text-danger">*</span>
                  </label>
                  <div className="cal-icon">
                    <DatePicker
                      selected={selectedDate2}
                      onChange={handleDateChange2}
                      className="form-control datetimepicker"
                      type="date"
                      dateFormat="dd-MM-yyyy"
                    />
                  </div>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    To <span className="text-danger">*</span>
                  </label>
                  <div className="cal-icon">
                    <DatePicker
                      selected={selectedDate2}
                      onChange={handleDateChange2}
                      className="form-control datetimepicker"
                      type="date"
                      dateFormat="dd-MM-yyyy"
                    />
                  </div>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Number of days <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    readOnly
                    type="text"
                    defaultValue={2}
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Remaining WFM <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    readOnly
                    defaultValue={3}
                    type="text"
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Leave WFM <span className="text-danger">*</span>
                  </label>
                  <textarea
                    rows={4}
                    className="form-control"
                    defaultValue={"Going to hospital"}
                  />
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    type="reset"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default EmployeeWFHModelPopup;
      