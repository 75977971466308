import { Table } from "antd";
import { Link } from "react-router-dom";
import {
  Avatar_19,
  Avatar_07,
  Avatar_06,
  Avatar_14,
} from "../../../../../Routes/ImagePath/index";
import testimg from "../../../../../assets/img/test.png"
import { useEffect } from "react";

const ClientTable = ({secId,secConfig,feeder75,secData}) => {
  console.log("feeder75feeder75",feeder75,secData)
  let finalData = [{...secData,"FEEDER_CAPACITY":feeder75?.FEEDER_CAPACITY}]
 const getOneSectionDetails = async ()=>{
  //  const await = 
 }

  useEffect(()=>{

  },[])


  let issueArr = [secData]

  const month = ['JANUARY','FEBRUARY','MARCH','APRIL',',MAY','JUNE']
  
  console.log("secConfigsecConfig",secConfig.split('|'))
  let newArr = []
  month.map((item, index)=>{
    if(index == 0){
      newArr.push({month_name:item,  FEEDER_LOAD:feeder75?.FEEDER_LOAD_JAN,LOAD_PRECEN:feeder75?.LOAD_PERCEN_JAN,CRITICAL_CATEGORY:feeder75?.CRITICAL_CATEGORY})
    }else if(index == 1){
      newArr.push({month_name:item,  FEEDER_LOAD:feeder75?.FEEDER_LOAD_FEB,LOAD_PRECEN:feeder75?.LOAD_PERCEN_FEB,CRITICAL_CATEGORY:feeder75?.CRITICAL_CATEGORY})
    }else if(index == 2){
      newArr.push({month_name:item,  FEEDER_LOAD:feeder75?.FEEDER_LOAD_MAR,LOAD_PRECEN:feeder75?.LOAD_PERCEN_MAR,CRITICAL_CATEGORY:feeder75?.CRITICAL_CATEGORY})
    } else if(index ==3){
      newArr.push({month_name:item,  FEEDER_LOAD:feeder75?.FEEDER_LOAD_APR,LOAD_PRECEN:feeder75?.LOAD_PERCEN_APR,CRITICAL_CATEGORY:feeder75?.CRITICAL_CATEGORY})
    } else if(index ==4){
      newArr.push({month_name:item,  FEEDER_LOAD:feeder75?.FEEDER_LOAD_MAY,LOAD_PRECEN:feeder75?.LOAD_PERCEN_MAY,CRITICAL_CATEGORY:feeder75?.CRITICAL_CATEGORY})
    } else {
      newArr.push({month_name:item,  FEEDER_LOAD:feeder75?.FEEDER_LOAD_JUNE,LOAD_PRECEN:feeder75?.LOAD_PERCEN_JUNE,CRITICAL_CATEGORY:feeder75?.CRITICAL_CATEGORY})

    }
   })
  // secConfig.split('|').map((item)=>{
  //  let x = item.split(',')
  //  console.log("xxxxxx",x)
  //  newArr.push({
  //   cable_name:x[0],
  //   cable_type:x[1],
  //   cable_size:x[2],
  //   cables:x[3],
  // joints:x[4],

  //  })

  
  // //  x.map((elem,index)=>{
  // //   console.log("elemelemelem",elem)
  // // //  newArr.push({
  // // //   cable_name:x[0],
  // // //   cable_type:x[1],
  // // //   cable_size:x[2],
  // // //   cables:elem[3],
  // // // joints:elem[4],

  // // //  })
  // //  })
  // })
  console.log("newArrnewArr",newArr)

  const columns = [
    {
      title: "SECTION",
      dataIndex: "ID",
      // render: (text, record) => (
      //   <h2 className="table-avatar">
      //     <Link to="#" className="avatar">
      //       <img alt="" src={record.image} />
      //     </Link>
      //     <Link to="#" className="ant-table-cell">
      //       {text} <span>{record.role}</span>
      //     </Link>
      //   </h2>
      // ),
      // sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "COMMISSION DATE",
      dataIndex: "COMMISSION_DATE",
      render: (text) => {
        // Convert the string to a Date object
        const date = new Date(text);
        // Format the date to "YYYY-MM-DD"
        const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
        return formattedDate;
      },
      // sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "AGE",
      dataIndex: "AGEING",
      // sorter: (a, b) => a.email.length - b.email.length,
    },
  
    {
      title: "CONSUMER CONNECTED",
      dataIndex: "NO_OF_CONSUMERS",
    
      // sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "FEEDER CAPACITY",
      dataIndex: "FEEDER_CAPACITY",
      // sorter: true,
 
    },
  ];

  const columns_months = [
    {
      title: "MONTH",
      dataIndex: "FEEDER_LOAD",
      render: (text, record,index) => (
        <h2 className="table-avatar">
      
          {/* <Link to="#" className="ant-table-cell"> */}
            <span>{month[index]}</span>
          {/* </Link> */}
        </h2>
      ),
      // sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "FEEDER LOAD",
      dataIndex: "FEEDER_LOAD",
      // sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "LOAD %",
      dataIndex: "LOAD_PRECEN",
      // sorter: (a, b) => a.email.length - b.email.length,
    },
  
    {
      title: "CRITICAL CATEGORY",
      dataIndex: "CRITICAL_CATEGORY",
    
      // sorter: (a, b) => a.status.length - b.status.length,
    },
  ];

  const columnsIssues = [
    {
      title: "FEEDER > 75 %",
      dataIndex: "FEEDER_75",
      render: (text) => {
        let isIssue = text == 1 ? "YES" : "NO"
        return isIssue;
      },
      // sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "LOAD SHEDDING",
      dataIndex: "LOAD_SHEDDING",
      render: (text) => {
        let isIssue = text == 1 ? "YES" : "NO"
        return isIssue;
      },
      // sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "BOTTLENECK",
      dataIndex: "BOTTLENECK",
      render: (text) => {
        let isIssue = text == 1 ? "YES" : "NO"
        return isIssue;
      },
      // sorter: (a, b) => a.email.length - b.email.length,
    },
  
    {
      title: "AGEING",
      dataIndex: "AGEING",
      render: (text) => {
        let isIssue = text == 1 ? "YES" : "NO"
        return isIssue;
      },
      // sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "CCC",
      dataIndex: "CCC",
      render: (text) => {
        let isIssue = text == 1 ? "YES" : "NO"
        return isIssue;
      },
      // sorter: true,
 
    },
    {
        title: "REPEATED SCHEMES",
        dataIndex: "REPEATED_SCHEMES",
        render: (text) => {
        
          let isIssue = text == 1 ? "YES" : "NO"
          return isIssue;
        },
        // sorter: true,
   
      },
      {
        title: "RED FLAG",
        dataIndex: "RED_FLAG",
        render: (text) => {
        
          let isIssue = text == 1 ? "YES" : "NO"
          return isIssue;
        },
        // sorter: true,
   
      },
      {
        title: "RADIAL TO RING",
        dataIndex: "RADIAL_TO_RING",
        render: (text) => {
        
          let isIssue = text == 1 ? "YES" : "NO"
          return isIssue;
        },
        // sorter: true,
   
      },
      {
        title: "SHIFTING",
        dataIndex: "SHIFTING",
        // sorter: true,
        render: (text) => {
        
          let isIssue = text == 1 ? "YES" : "NO"
          return isIssue;
        },
   
      },
      {
        title: "PILC REMOVAL",
        dataIndex: "PILC_REMOVAL",
        render: (text) => {
        
          let isIssue = text == 1 ? "YES" : "NO"
          return isIssue;
        },
        // sorter: true,
   
      }
  ];
  return (
    <>
    <div className="col-md-12">
      <div className="card card-table flex-fill">
        <div className="card-header">
          <h3 className="card-title mb-0">SINGLE LINE DIAGRAM SECTION</h3>
        </div>
        <div className="card-body flex">
          <div className="table-responsive">
          <div className="row">
          <div  className="col-md-7" style={{textAlign:"center"}}>
          <Table
              dataSource={finalData?.length > 0 ? finalData : []}
              columns={columns}
              pagination={false}
              rowKey={(record) => record.id}
            />
          </div>
          <div  className="col-md-5">
          <Table
              dataSource={newArr?.length > 0 ? newArr : []}
              columns={columns_months}
              pagination={false}
              rowKey={(record) => record.id}
            />
          </div>
         
  </div>
           
         
          </div>
        </div>
        {/* <div className="card-footer">
          <Link to="/clients">View all clients</Link>
        </div> */}
      </div>
    </div>
    <div className="col-md-12" style={{display :"flex",justifyContent:"space-around"}}>
    {/* https://tripura.saielectricity.com/sld/1.png */}
          <img src={`https://tripura.saielectricity.com/sld/${secId}.png`} 
          style={{height:"350px"}} onDoubleClick={() => window.open(`https://tripura.saielectricity.com/sld/${secId}.png`, '_blank')} />
          <div>

        <h3 className="card-title " style={{textAlign:"justify" ,marginTop:"50px"}}>ISSUES FOUND</h3>
             
             <Table
              dataSource={issueArr?.length > 0 ? issueArr : []}
              columns={columnsIssues}
              pagination={false}
              rowKey={(record) => record.id}
            />
          </div>
    </div>
    </>
  );
};

export default ClientTable;
