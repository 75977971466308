import { Table } from "antd";
// import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import EmployeeLeaveModelPopup from "./EmployeeLeaveModelPopup";
import EmployeeWFHModelPopup from "./EmployeeWFHModelPopup";
import EmployeeVisitModelPopup from "./EmployeeVisitModelPopup";
import SearchBox from "../../../components/SearchBox";
import { getEmpData } from "../../Services/ApiCalling"
import moment from "moment"

export const EmployeeLeave = ({empStatsData}) => {

  {console.log("empStatsDataempStatsData",empStatsData)}

  const [users, setUsers] = useState();

  const token =  localStorage.getItem("LogIn_Token")  

   const empData = async (token)=>{
    const resp = await getEmpData(token)
    setUsers(resp.data)
    //console.log('resppp', resp.data);
  }

  useEffect( () => {
    empData(token);
  }, []);

  function mouseOver(event) {
    event.target.style.background = "#FF9F43";
    event.target.style.color = "white";
  }
  function mouseOut(event) {
    event.target.style.background = "white";
    event.target.style.color = "black";
  }

  const columns = [
    {
      title: "Request Type",
      dataIndex: "REQ_TYPE",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.REQ_TYPE.length - b.REQ_TYPE.length,
    },

    {
      title: "From",
      dataIndex: "REQ_FROM",
      render: (text) => <span>{ moment(text).format('DD-MM-YYYY') }</span>,
      sorter: (a, b) => a.REQ_FROM.length - b.REQ_FROM.length,
    },
    {
      title: "To",
      dataIndex: "REQ_TO",
      render: (text) => <span>{ moment(text).format('DD-MM-YYYY') }</span>,
      sorter: (a, b) => a.REQ_TO - b.REQ_TO,
    },

    {
      title: "No Of Days",
      dataIndex: "NO_DAYS",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.NO_DAYS.length - b.NO_DAYS.length,
    },

     {
      title: "Reason",
      dataIndex: "REASON",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.REASON.length - b.REASON.length,
    },
    {
      title: "Status",
      dataIndex: "REQ_STATUS",
      sorter: true,
      render: (text) => <span>{text}</span>,
    },
    // {
    //   title: "Action",
    //   className: "text-end",
    //   sorter: true,
    //   render: () => (
    //     <div className="dropdown dropdown-action text-end">
    //       <Link
    //         to="#"
    //         className="action-icon dropdown-toggle"
    //         data-bs-toggle="dropdown"
    //         aria-expanded="false"
    //       >
    //         <i className="material-icons">more_vert</i>
    //       </Link>
    //       <div className="dropdown-menu dropdown-menu-right">
    //         <Link
    //           className="dropdown-item"
    //           to="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#edit_leave"
    //         >
    //           <i className="fa fa-pencil m-r-5" /> Edit
    //         </Link>
    //         <Link
    //           className="dropdown-item"
    //           to="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#delete"
    //         >
    //           <i className="fa fa-trash m-r-5" /> Delete
    //         </Link>
    //       </div>
    //     </div>
    //   ),
    // },
  ];
  const leaveStats = [
    {
      id: 1,
      title: "Total Leave",
      value: empStatsData.TOTAL_LEAVE      ,
    },
    {
      id: 2,
      title: "Pending Leave/Current Leave",
      value: empStatsData.pendingRequestsCount,
    },
    {
      id: 3,
      title: "Remaining Leave",
      value: empStatsData.REM_LEAVE,
    },
  ];

  return (
    <>
       <ToastContainer />
      <div className="page-wrapper">
        <div className="content container-fluid">

          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">{"Applications"}</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin-dashboard">{"Dashboard"}</Link>
                </li>
                <li className="breadcrumb-item active">{"Leave, WFM, OFCL_Visit"}</li>
              </ul>
            </div>
            <div className="col-auto float-end ms-auto">

              <div className="dropdown">
                <button className="btn add-btn dropdown-toggle rounded-2" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="fa fa-plus" />ADD
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li className="m-2">
                    <Link
                      to="#"
                      className="btn dropdown-item rounded-2"
                      onMouseOver={mouseOver} onMouseOut={mouseOut}
                      data-bs-toggle="modal"
                      data-bs-target={"#add_leavee"}
                    >
                      {/* <i className="fa fa-plus" /> */}
                      {"Leave"}
                    </Link>
                  </li>
                  <li className="m-2">
                    <Link
                      to="#"
                      className="btn dropdown-item rounded-2"
                      onMouseOver={mouseOver} onMouseOut={mouseOut}
                      data-bs-toggle="modal"
                      data-bs-target={"#add_wfh"}
                    >
                      {/* <i className="fa fa-plus" /> */}
                      {"WFH"}
                    </Link>
                  </li>
                  <li className="m-2">
                    <Link
                      to="#"
                      className="btn dropdown-item rounded-2"
                      onMouseOver={mouseOver} onMouseOut={mouseOut}
                      data-bs-toggle="modal"
                      data-bs-target={"#add_Visit"}
                      // onClick={}
                    >
                      {/* <i className="fa fa-plus" /> */}
                      {"Official Visit"}
                    </Link>
                  </li>
                </ul>
              </div>

            </div>
          </div>

          <div className="row">
            {leaveStats.map((stat, index) => (
              <div className="col-md-4" key={index}>
                <div className="stats-info">
                  <h6>{stat.title}</h6>
                  <h4>{stat.value}</h4>
                </div>
              </div>
            ))}
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <SearchBox />
                {users && users.length > 0 &&<Table
                  columns={columns}
                  dataSource={users?.length > 0 ? users : []}
                  className="table-striped" />}
              </div>
            </div>
          </div>
        </div>
      </div>

      <EmployeeLeaveModelPopup requestType="Leave" empData={empData} />
      <EmployeeWFHModelPopup requestType="WFH" empData={empData}/>
      <EmployeeVisitModelPopup requestType="OFFICIAL_TOUR" empData={empData} />
      {/* <EmpLeaveModal/> */}
      {/* <EmpWfhModal/> */}

      {/* Delete Modal */}
      <DeleteModal Name="Delete Leaves" />
      {/* Delete Modal */}
    </>
  );
};

export default EmployeeLeave;