import React, { useState, useEffect } from 'react';
// import Index from "./components/UserProfile";
import Profile from './components/UserProfile';
import { getUserProfileData } from '../Services/ApiCalling';

function ProfileView() {
  const [userData, setuserData] = useState(null);
  
  const token = localStorage.getItem("LogIn_Token")

  const fetchProdileData = async () => {
    const getProfile = await getUserProfileData(token)
    console.log("getProfileDatagetProfileData", getProfile)
    setuserData(getProfile.data)
  }

  useEffect(() => {
    fetchProdileData()
  }, [])

  return (
    <>
      {userData &&<Profile userData={userData} />}
    </>
  );
}

export default ProfileView;
