import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
// import "../../../../../assets/css/calendar.css";
import CalendarModal from "../../../components/modelpopup/CalendarModal";

function PersonCalender({calendarData}) {

    const [weekendsVisible, setWeekendVisible] = useState(true);
    const finalArray = []
     console.log("calendarDatacalendarData",calendarData)
    calendarData.map((item)=>{
        let from = Date.parse(item.REQ_FROM)
        let to = Date.parse(item.REQ_TO)
        let btw = parseInt((to-from)/(60*60*1000*24))
        console.log("btw",btw)
        for(let i = 0; i< btw + 1; i++){
            console.log("rashi", from+(i*86400000))
            // let x  = from+(i*86400000)
            if(item.REQ_STATUS == "PENDING"){
            finalArray.push({title : item.REASON,start:from+(i*86400000) ,
                className: "bg-purple"})
               
            }else if(item.REQ_STATUS == "APPROVED"){
            finalArray.push({title : item.REASON, start: from+(i*86400000),
                className: "bg-success"})
             
            }else{
            finalArray.push({title : item.REASON, start: from+(i*86400000),
                className: "bg-info"})
             
            }
        }
    })
    console.log("finalArrayfinalArrayfinalArray",finalArray)
    // function helper(reqData){
        
    // }

   
    let x = new Date("2024-09-23")
    let y = new Date("1727049600000")
    console.log("dateeeee", calendarData[0], new Date(y))
    const defaultEvents = [
        {
            title: "Event Name 4",
            start: Date.now() - 338000000,
            className: "bg-purple",
        },
        {
            title: "Test Event 1",
            start: Date.now(),
            end: Date.now(),
            className: "bg-success",
        },
        {
            title: "Test Event 2444",
            start: 1727116200000,
            className: "bg-info",
        },
        {
            title: "Test Event 3555",
            start: Date.now() + 338000000,
            className: "bg-primary",
        },
    ];
    return (
        <>
            <div className="page-wrapper">
                <div className="content container-fluid">
                    {/* Page Header */}
                    <div className="page-header">
                    </div>
                    {/* /Page Header */}
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card mb-0">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {/* Calendar */}
                                            <div id="calendar" />
                                            <FullCalendar
                                                plugins={[
                                                    dayGridPlugin,
                                                    timeGridPlugin,
                                                    interactionPlugin,
                                                ]}
                                                headerToolbar={{
                                                    left: "prev,next today",
                                                    center: "title",
                                                    right: "dayGridMonth,timeGridWeek,timeGridDay",
                                                }}
                                                initialView="dayGridMonth"
                                                editable={true}
                                                selectable={true}
                                                selectMirror={true}
                                                dayMaxEvents={true}
                                                weekends={weekendsVisible}
                                                initialEvents={finalArray.length > 0 ? finalArray : []}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <CalendarModal />
                </div>
            </div>
        </>
    )
}

export default PersonCalender
