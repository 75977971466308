import axios from "axios";
import React, { useEffect, useState } from "react";
import { base_url } from "../../../../../base_urls";

const Reports = ({setColName,setSecId}) => {
  const [users, setUsers] = useState([
    {
      "title": "FEEDER > 75 %",
      // "changePercentage": "+12.5%",
      "value": "10",
      "previousValue": "",
       "no_of_record":"41",
      "col_name":"FEEDER_75"
    },
    {
      "title": "LOAD SHEDDING",
      // "changePercentage": "1.31%",
      "value": "5",
       "no_of_record":"22",
      "col_name":"LOAD_SHEDDING"

    },
    {
      "title": "BOTTLENECK",
      // "changePercentage": "26.14%",
      "value": "10",
       "no_of_record":"45",
      "col_name":"BOTTLENECK"
      
    },
    {
      "title": "AGEING",
      // "changePercentage": "26.14%",
      "value": "5",
      "no_of_record":"190",
      "col_name":"AGEING"

      
 
    },
    {
      "title": "CCC",
      // "changePercentage": "26.14%",
      "value": "10",
       "no_of_record":"633",
      "col_name":"CCC"

 
    },
    {
      "title": "REPEATED SCHEMES",
      // "changePercentage": "26.14%",
      "value": "10",
       "no_of_record":"358",
      "col_name":"REPEATED_SCHEMES"

 
    }
  ]);
  const [riskData] = useState([
    {
      "title": "RED FLAG",
      "changePercentage": "+23.8%",
      "value": 15,
      "previousValue": "",
       "no_of_record":"496",
      "col_name":"RED_FLAG"
    },
    {
      "title": "RADIAL TO RING",
      "changePercentage": "+23.8%",
      "value": 15,
      "previousValue": "",
      "no_of_record":"191",
      "col_name":"RADIAL_TO_RING"
    },
    {
      "title": "SHIFTING",
      "changePercentage": "+23.8%",
      "value": 10,
      "previousValue": "",
      "no_of_record":"11",
      "col_name":"SHIFTING"

    },
    {
      "title": "PILC REMOVAL",
      "changePercentage": "+23.8%",
      "value": 10,
      "previousValue": "",
      "no_of_record":"683",
      "col_name":"PILC_REMOVAL"

    }
  ])

  useEffect(() => {
    // axios
      // .get(base_url + "/api/dashreports.json")
      // .then((res) => setUsers(res.data));
  }, []);
  return (
    <>
    <div>
      <div className="row">
        <div className="col-md-12">
          {/* <span>HEALTH PARAMETERS</span> */}
          
                      <h3 className="card-title mb-0">HEALTH PARAMETERS</h3>
          <div className="card-group m-b-30" style={{cursor:"pointer"}}>            
            {Array.isArray(users) && users.length > 0 ? (
              users.map((item, index) => (
                <div className="card" key={index} onClick={()=>{
                  setColName(item.col_name)
                  setSecId()
                  }}>
                  <div className="card-body">
                    <div className="d-flex justify-content-between mb-3">
                      <div>
                        <span className="d-block">{item.title}</span>
                      </div>
                      <div>
                        <span
                          // className={`text-${
                          //   item.changePercentage.includes("+")
                          //     ? "success"
                          //     : "danger"
                          // }`}
                        >
                          {`${item.value}`}
                        </span>
                      </div>
                    </div>
                    
                    <h3 className="mb-3">{item.no_of_record}</h3>
                    {/* <div className="progress mb-2" style={{ height: "5px" }}> */}
                      {/* <div
                        className="progress-bar bg-primary"
                        role="progressbar"
                        style={{ width: "70%" }}
                        aria-valuenow={40}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      /> */}
                    {/* </div> */}
                    <p className="mb-0">
                      {item.changeDescription}
                      <span className="text-muted"> {item.previousValue}</span>
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <p>No data available</p>
            )}
          </div>
        </div>
      </div>
    </div>
    <div>
    <div className="row">
      <div className="col-md-12">
      <div className="dash-widget-info" style={{textAlign:"left"}}>
                        {/* <h3>{"Andheri"}</h3> */}
                        {/* <h1>{"RISK PARAMETERS"}</h1> */}
                      <h3 className="card-title mb-0">RISK PARAMETERS</h3>

                      </div>
        <div className="card-group m-b-30">
          {Array.isArray(riskData) && riskData.length > 0 ? (
            riskData.map((item, index) => (
              <div className="card" key={index} onClick={()=>{
                setColName(item.col_name)
                setSecId()
                }}>
                <div className="card-body" style={{cursor:"pointer"}}>
                  <div className="d-flex justify-content-between mb-3">
                    <div>
                      <span className="d-block">{item.title}</span>
                    </div>
                    <div>
                      <span
                        // className={`text-${
                        //   item.changePercentage.includes("+")
                        //     ? "success"
                        //     : "danger"
                        // }`}
                      >
                        {item.value}
                      </span>
                    </div>
                  </div>
                  <h3 className="mb-3">{item.no_of_record}</h3>
                  {/* <div className="progress mb-2" style={{ height: "5px" }}>
                    <div
                      className="progress-bar bg-primary"
                      role="progressbar"
                      style={{ width: "70%" }}
                      aria-valuenow={40}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div> */}
                  <p className="mb-0">
                    {item.changeDescription}
                    <span className="text-muted"> {item.previousValue}</span>
                  </p>
                </div>
              </div>
            ))
          ) : (
            <p>No data available</p>
          )}
        </div>
      </div>
    </div>
    </div>
    </>
  );
};

export default Reports;
