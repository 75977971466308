import { Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { base_url } from "../../../../../base_urls";

const PaymentTable = ({users,isLoading,currentPage,setPageSize,pageSize,setCurrentPage,totalRecords,setSecId,setSecConfig,setSecData,col_name}) => {
  const [values, setValues] = useState(users);
  console.log("usersusers",users,values)
  // useEffect(() => {
  //   // axios
  //   //   .get(base_url + "/api/dashTabletwo.json")
  //   //   .then((res) => setValues(res.data));
  // }, []);
  useEffect(() => {
    setValues(users);
    
  }, [users]);

  const columns = [
    {
      title: "SECTION",
      dataIndex: "ID",
      render: (text,record) => {
        return (
        <span style={{cursor:"pointer"}} onClick={()=>{
       console.log("text",text,record)
       setSecId(text)
       setSecConfig(record.SEC_CONFIG)
       setSecData(record)
        }}>
          {text}
        </span>
      )
      },
      // sorter: (a, b) => a.invoiceID.length - b.invoiceID.length,
    },
    {
      title: "FEEDER ID",
      dataIndex: "FEEDERID",
      // sorter: (a, b) => a.invoiceID.length - b.invoiceID.length,
    },
    {
      title: "SOURCE S/S",
      dataIndex: "SOURCE_SS",
      // sorter: (a, b) => a.client.length - b.client.length,
    },
    {
      title: "SOURCE SW",
      dataIndex: "SOURCE_SWI",
      // sorter: (a, b) => a.paymentType.length - b.paymentType.length,
    },
    {
      title: "DESTIONATION S/S",
      dataIndex: "DESTINATIO",
      // sorter: (a, b) => a.paidDate.length - b.paidDate.length,
    },
    {
      title: "DESTIONATION SW",
      dataIndex: "DESTINAT_1",
      // sorter: (a, b) => a.CRITICAL_SCORE.length - b.CRITICAL_SCORE.length,
    },
    {
      title: "CRITICAL LEVEL",
      dataIndex: "CRITICAL_SCORE",
      // sorter: (a, b) => a.CRITICAL_SCORE?.length - b.CRITICAL_SCORE?.length,
      sorter: (a, b) => (a.CRITICAL_SCORE || 0) - (b.CRITICAL_SCORE || 0),
    },
   
    {
      title: "SECTION HEALTH SCORE",
      dataIndex: "HEALTH_SCORE",
      render: (text, record) => 
        
        { console.log("text",text)
          return (
        <div className="progress progress-xs progress-striped">
        <div
          className="progress-bar"
          role="progressbar"
          data-bs-toggle="tooltip"
          title={`${text}%`}
          style={{ width: `${text}%` }}
        />
      </div>
      )},
      // sorter: (a, b) => a.HEALTH_SCORE.length - b.HEALTH_SCORE.length,
    },
    {
      title: "NEW CRITICAL LEVEL",
      dataIndex: "NEW_CRITICAL_LEVEL",
      // sorter: (a, b) => a.NEW_CRITICAL_LEVEL?.length - b.NEW_CRITICAL_LEVEL?.length,
      sorter: (a, b) => (a.NEW_CRITICAL_LEVEL || 0) - (b.NEW_CRITICAL_LEVEL || 0),

    },
    {
      title: "SECTION NEW HEALTH SCORE",
      dataIndex: "NEW_HEALTH_SCORE",
      render: (text, record) => 
        
        { console.log("text",text)
          return (
        <div className="progress progress-xs progress-striped">
        <div
          className="progress-bar"
          role="progressbar"
          data-bs-toggle="tooltip"
          title={`${text}%`}
          style={{ width: `${text}%` }}
        />
      </div>
      )},
      // sorter: (a, b) => a.HEALTH_SCORE.length - b.HEALTH_SCORE.length,
    },
    {
      title: "REPEATED SCHEME INVESTMENT",
      dataIndex: "REPEATED_SCHEME_INVESTMENT",
      // sorter: (a, b) => a.CRITICAL_SCORE.length - b.CRITICAL_SCORE.length,
    },
    {
      title: "FEEDER GREATER THAN 75 INVESTMENT",
      dataIndex: "FEEDER_75_INVESTMENT",
      // sorter: (a, b) => a.CRITICAL_SCORE.length - b.CRITICAL_SCORE.length,
    },
    {
      title: "BOTTLENECK INVESTMENT",
      dataIndex: "BOTTLENECK_INVESTMENT",
      // sorter: (a, b) => a.CRITICAL_SCORE.length - b.CRITICAL_SCORE.length,
    },
    {
      title: "PILC REMOVAL INVESTMENT",
      dataIndex: "PILC_REMOVAL_INVESTMENT",
      // sorter: (a, b) => a.CRITICAL_SCORE.length - b.CRITICAL_SCORE.length,
    },
    {
      title: "RADIAL TO RING INVESTMENT",
      dataIndex: "RADIAL_TO_RING_INVSTMENT",
      // sorter: (a, b) => a.CRITICAL_SCORE.length - b.CRITICAL_SCORE.length,
    },
    {
      title: "SHIFTING INVESTMENT",
      dataIndex: "SHIFTING_INVESTMENT",
      // sorter: (a, b) => a.CRITICAL_SCORE.length - b.CRITICAL_SCORE.length,
    },
    {
      title: "POPULATION DENSITY INVESTMENT",
      dataIndex: "POPULATION_DENSITY_AREA_INVESTMENT",
      // sorter: (a, b) => a.CRITICAL_SCORE.length - b.CRITICAL_SCORE.length,
    },
    {
      title: "AGEING INVESTMENT",
      dataIndex: "AGEING_INVESTMENT",
      // sorter: (a, b) => a.CRITICAL_SCORE.length - b.CRITICAL_SCORE.length,
    },
  ];

  const renderPlaceholder = () => (
    <>
      <p className="placeholder-glow">
        <span className="placeholder col-12" />
      </p>
      <p className="placeholder-wave mb-0">
        <span className="placeholder col-12" />
      </p>
    </>
  );

  const placeholderData = new Array(5).fill({}); 
  return (
    <>
    <div className="col-md-12 d-flex">
      <div className="card card-table flex-fill" style={{overflowY:"auto",height:"450px"}}>
          <div className="card-header">
            <h3 className="card-title mb-0">{`SECTIONS OF ${col_name == "FEEDER_75" ? "FEEDER GREATER THAN 75 %": 
              col_name == "LOAD_SHEDDING" ? "LOAD SHEDDING":col_name == "REPEATED_SCHEMES" ? "REPEATED SCHEMES":col_name=="RED_FLAG" ? "RED FLAG" 
              :col_name=="RADIAL_TO_RING" ? "RADIAL TO RING":col_name =="PILC_REMOVAL" ? "PILC REMOVAL" : col_name}`}</h3>
          </div>
        <div className="card-body">
          <div className="table-responsive">
            <Table
            dataSource={isLoading ? placeholderData : values?.length > 0 ? values : []}
            columns={
              isLoading
                ? columns.map((col) => ({
                    ...col,
                    render: () => renderPlaceholder(), // Render placeholder for all columns
                  }))
                : columns
            }
              // dataSource={values?.length > 0 ? values : []}
              // columns={columns}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalRecords,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                },
              }}
              rowKey={(record) => record.id}
              // scroll={{
              //   x: 1000, // Set a larger value to ensure horizontal scrolling
              //   y: 400,  // Set your desired height for vertical scrolling
              // }} 
            />
          </div>
        </div>
        {/* <div className="card-footer">
          <Link to="/payments">View all payments</Link>
        </div> */}
      </div>
    </div>
    </>
  );
};

export default PaymentTable;
