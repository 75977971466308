/* eslint-disable no-unused-expressions */

import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Table } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import SearchBox from "../../../components/SearchBox";
import LeaveFilter from "../../../components/LeaveFilter";
import ApplicationModal from "./application";
import {  LeaveDetails, LeaveStatus } from "../../Services/ApiCalling";
import moment from 'moment';

const AdminLeave = ({ leaveUsers, getLeaveData, emp_sts }) => {
  const [fieldInputs, setFieldInputs] = useState(false);
  const [reqState, setReqState] = useState()
  const [isFullScreen, setFullScreen] = useState(false);
  const maximizeBtnRef = useRef(null);
  const [status, setStatus] = useState("Pending");
  const [remarks, setRemarks] = useState("");
  const [id, setId] = useState(null);
  const [emp_id,setEmpId] = useState()
  useEffect(() => {
    const handleClick = () => {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
        setFullScreen(true);
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
          setFullScreen(false);
        }
      }
    };

    const cleanup = () => {
      if (isFullScreen && document.exitFullscreen) {
        document.exitFullscreen();
        setFullScreen(false);
      }
    };

    const maximizeBtn = maximizeBtnRef.current;
    maximizeBtn.addEventListener("click", handleClick);

    // Cleanup function to remove the event listener and exit fullscreen on component unmount
    return () => {
      maximizeBtn.removeEventListener("click", handleClick);
      cleanup();
    };
  }, [isFullScreen]);

  console.log("remarksremarks", status, remarks, id)

  const token = localStorage.getItem("LogIn_Token")


  const handleSubmit = async () => {
    const obj = {
      ID: id,
      STATUS: status,
      REMARK: remarks
    };
    try {
      const response = await LeaveStatus(token, obj);
      console.log('Response:', response.data);
      getLeaveData(token)
    } catch (error) {
      console.error('Error:', error);
    }
    console.log("LeaveUsers..." ,leaveUsers)
  };

  const columns = [
    {
      title: "Employee Name",
      dataIndex: "  EMP_NAME",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.leavetype.length - b.leavetype.length,
    },
    {
      title: "Employee Id",
      dataIndex: "EMP_ID",
      render: (text, record) => {

        return (
          <span className="table-avatar" onClick={() => { console.log("record", record) }}>
            <img className="avatar" alt="" src={record.image} />
            {/* <Link to="/profile" className="avatar">
          </Link> */}
            <span className="table-avatar">
              {/* <Link to="#" className="avatar"></Link> */}
              {text}
            </span>
          </span>
        )
      },
      sorter: (a, b) => a.name.length - b.name.length,
    },

    {
      title: "Application Type",
      dataIndex: "REQ_TYPE",
      render: (text) => <span>{text}</span>,

      sorter: (a, b) => a.leavetype.length - b.leavetype.length,
    },

    {
      title: "From",
      dataIndex: "REQ_FROM",
      render: (text) => <span>{moment(text).format('DD-MM-YYYY')}</span>,
      sorter: (a, b) => new Date(a.REQ_FROM) - new Date(b.REQ_FROM),
    },
    {
      title: "To",
      dataIndex: "REQ_TO",
      render: (text) => <span>{moment(text).format('DD-MM-YYYY')}</span>,
      sorter: (a, b) => new Date(a.REQ_TO) - new Date(b.REQ_TO),
    },

    {
      title: "No Of Days",
      dataIndex: "NO_DAYS",
      render: (text) => <span>{text}</span>,

      sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },

    {
      title: "Reason",
      dataIndex: "REASON",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.reason.length - b.reason.length,
    },
    {
      title: "Status",
      dataIndex: "REQ_STATUS",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Action",
      render: (record) => {
        return (
          <div className="dropdown dropdown-action text-end">
            <Link
              to="#"
              className="action-icon dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="material-icons">more_vert</i>
            </Link>
            <div className="dropdown-menu dropdown-menu-right">
              <Link
                className="dropdown-item"
                to="#"
                data-bs-toggle="modal"
                data-bs-backdrop="static"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
                // data-bs-target="#edit_leave"
                data-bs-target="#test"
              >

                <i className="fa fa-pencil m-r-5" /> &nbsp;
                <span onClick={() => {
                  setReqState(record)
                  console.log("recor record record" ,record)
                }}>Status</span>
              </Link>

              {/* <Link
                className="dropdown-item"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#delete"
              >
                <i className="fa-regular fa-trash-can m-r-5" />Delete
              </Link> */}
            </div>
          </div>
        )
      },
      sorter: true,
    },
  ];

  return (
    <>
      {/* <submitToast/> */}
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-md-4">
                <h3 className="page-title">Leaves</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Leaves</li>
                </ul>
              </div>
              <div className="col-md-8 float-end ms-auto">
                <div className="d-flex title-head">
                  <div className="view-icons">
                    <Link to="#" className="grid-view btn btn-link">
                      <i className="las la-redo-alt" />
                    </Link>
                    <Link
                      to="#"
                      className="list-view btn btn-link"
                      id="collapse-header"
                      ref={maximizeBtnRef}
                    >
                      <i className="las la-expand-arrows-alt" />
                    </Link>
                    <Link
                      to="#"
                      className={
                        fieldInputs
                          ? "list-view btn btn-link active-filter"
                          : "list-view btn btn-link"
                      }
                      id="filter_search"
                      onClick={() => setFieldInputs(!fieldInputs)}
                    >
                      <i className="las la-filter" />
                    </Link>
                  </div>

                </div>
              </div>
            </div>
          </div>
          {/* Page Header */}
          <hr />
          {/* Leave Statistics */}
          {/* <div className="row">
            {statsData.map((stat, index) => (
              <div className="col-md-3 d-flex" key={index}>
                <div className="stats-info w-100">
                  <h6>{stat.title}</h6>
                  {stat.subText ? (
                    <h4>
                      {stat.value} <span>{stat.subText}</span>
                    </h4>
                  ) : (
                    <h4>{stat.value}</h4>
                  )}
                </div>
              </div>
            ))}
          </div> */}
          <div
            className="filter-filelds"
            id="filter_inputs"
            style={{ display: fieldInputs ? "block" : "none" }}
          >
            <LeaveFilter />
          </div>
          {/* /Leave Statistics */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <SearchBox />
                {
                  <Table
                    columns={columns}
                    dataSource={leaveUsers?.length > 0 ? leaveUsers : []}
                    className="table-striped" />
                }
              </div>
            </div>
          </div>
        </div>

        {<ApplicationModal Name="Leave Status" test={"test"} reqState={reqState} setRemarks={setRemarks} setStatus={setStatus} setId={setId} handleSubmit={handleSubmit} leave_status={emp_sts} />}
        {/* Delete Modal */}
        <DeleteModal Name="Delete Leaves" />
        {/* Delete Modal */}
      </div>
    </>
  );
};

export default AdminLeave;
