import React, { useEffect, useState } from 'react'
import PersonProfile from './component/personProfile'
import PersonCalender from './component/personCalender'
import { getSpecificProfile } from '../Services/ApiCalling'

function PersonProfileView() {

  const [profileData, setProfileData] = useState();
  const [calendarData, setCalenderData] = useState();

  const token = localStorage.getItem("LogIn_Token") 
  const getProfileData = async (token) => {
    const res = await getSpecificProfile(token)
    setProfileData(res.data)
    setCalenderData(res.empRequest)
    console.log('resppp', res);
  }
  useEffect(() => {
    getProfileData(token);
  }, []);

  return (
    <div>
      {profileData && <PersonProfile profileData = {profileData}/>}
      {calendarData && <PersonCalender calendarData = {calendarData}/>}
    </div>
  )
}

export default PersonProfileView
