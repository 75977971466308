/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
// import "../../../../../assets/css/calendar.css";
import CalendarModal from "../../../components/modelpopup/CalendarModal";

const PersonProfile = ({ profileData }) => {

    const leaveStats = [
        {
            id: 1,
            title: "Total Leave",
            value: profileData.TOTAL_LEAVE,
        },
        {
            id: 2,
            title: "Pending Leave/Current Leave",
            value: profileData.pendingRequestsCount,
        },
        {
            id: 3,
            title: "Remaining Leave",
            value: profileData.REM_LEAVE,
        },
    ];


    return (
        <>
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <Breadcrumbs
                        maintitle="Profile"
                        title="Dashboard"
                        subtitle="Profile"
                        modal="#add_indicator"
                        name="Add New"
                    />


                    <div className="row">
                        {leaveStats.map((stat, index) => (
                            <div className="col-md-4" key={index}>
                                <div className="stats-info">
                                    <h6>{stat.title}</h6>
                                    <h4>{stat.value}</h4>
                                </div>
                            </div>
                        ))}
                    </div>


                    <div className="card mb-0">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="profile-view">

                                        <div className="profile-basic">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="profile-info-left">
                                                        <h3 className="user-name m-t-0 mb-0">
                                                            {/* {profileData.EMP_NAME} */}
                                                        </h3>

                                                        <small className="text-muted">
                                                            {profileData.EMP_CURR_DESG
                                                            }
                                                        </small>
                                                        <div className="staff-id">
                                                            Employee ID : {profileData.EMP_ID
                                                            }
                                                        </div>
                                                        <div className="small doj text-muted">
                                                            Date of Join : {profileData.EMP_DOJ}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-7">
                                                    <ul className="personal-info">
                                                        <li>
                                                            <div className="title">Phone:</div>
                                                            <div className="text">
                                                                <Link to={`tel:${profileData.EMP_MOBILE_NO
                                                                    }`}>
                                                                    {profileData.EMP_MOBILE_NO}
                                                                </Link>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="title">Email:</div>
                                                            <div className="text">
                                                                <Link to={`mailto:${profileData.EMP_EMAIL_ID}`}>
                                                                    {profileData.EMP_EMAIL_ID}
                                                                </Link>
                                                            </div>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pro-edit">
                                            <Link
                                                data-bs-target="#profile_info"
                                                data-bs-toggle="modal"
                                                className="edit-icon"
                                                to="#"
                                            >
                                                <i className="fa-solid fa-pencil"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PersonProfile;
