import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Applogo } from "../../../Routes/ImagePath";
import * as yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { emailrgx } from "./RegEx";
import { sendOtp } from "../../Services/ApiCalling";

// Validation schema
const schema = yup.object({
  email: yup
    .string()
    .matches(emailrgx, "Invalid email format")
    .required("Email is required")
    .trim(),
  NEW_PASSWORD: yup
    .string()
    .required("Required")
    .min(6, "Password must be at least 6 characters"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("NEW_PASSWORD"), null], "Passwords must match")
    .required("Required"),
});

const ForgotPassword = () => {
  const [passData, setPassData] = useState({});
  const [passwordEye, setPasswordEye] = useState(true);
  const [repeatPasswordEye, setRepeatPasswordEye] = useState(true);

  const navigate = useNavigate();

  const generateOtp = async (formData) => {
    try {
      console.log("to send data", formData);
      const body = {
        email: formData.email,
      };

      const passData = await sendOtp(formData);

      console.log("resss", passData);
      if (passData.Success === false) {
        alert(passData.Message);
        navigate("/register");
      } else if (passData.Success === true) {
        setPassData(passData.data);

        navigate("/otp", { state: { formData: passData.data } });
      }
    } catch (error) {
      console.log("error in generating otp", error);
    }
  };

  const initialValues = {
    email: "",
    NEW_PASSWORD: "",
    confirmPassword: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values) => {
        console.log("Form Data", values);
        generateOtp(values);
      }}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <Form onSubmit={handleSubmit}>
          <div className="account-page">
            <div className="main-wrapper">
              <div className="account-content">
                <div className="container">
                  {/* <div className="account-logo">
                    <Link to="/app/main/dashboard">
                      <img src={Applogo} alt="Dreamguy's Technologies" />
                    </Link>
                  </div> */}
                  <div className="account-box">
                    <div className="account-wrapper">
                      <h3 className="account-title">Forgot Password?</h3>
                      <p className="account-subtitle">
                        Enter your email to get a password reset link
                      </p>
                      <div className="input-block">
                        <label>Email Address</label>
                        <Field
                          className="form-control"
                          type="text"
                          name="email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          style={{ color: "red", textAlign: "center" }}
                        />
                      </div>

                      <div className="input-block mb-3">
                        <label className="col-form-label">Password</label>
                        <div
                          className="pass-group"
                          style={{ position: "relative" }}
                        >
                          <Field
                            type={passwordEye ? "password" : "text"}
                            name="NEW_PASSWORD"
                            className="form-control"
                            autoComplete="off"
                          />
                          <span
                            style={{
                              position: "absolute",
                              right: "5%",
                              top: "30%",
                            }}
                            onClick={() => setPasswordEye(!passwordEye)}
                            className={`fa toggle-password ${
                              passwordEye ? "fa-eye-slash" : "fa-eye"
                            }`}
                          />
                        </div>
                        <ErrorMessage
                          name="NEW_PASSWORD"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Confirm Password
                        </label>
                        <div
                          className="pass-group"
                          style={{ position: "relative" }}
                        >
                          <Field
                            type={repeatPasswordEye ? "password" : "text"}
                            name="confirmPassword"
                            className="form-control"
                            autoComplete="off"
                          />
                          <span
                            style={{
                              position: "absolute",
                              right: "5%",
                              top: "30%",
                            }}
                            onClick={() =>
                              setRepeatPasswordEye(!repeatPasswordEye)
                            }
                            className={`fa toggle-password ${
                              repeatPasswordEye ? "fa-eye-slash" : "fa-eye"
                            }`}
                          />
                        </div>
                        <ErrorMessage
                          name="confirmPassword"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="input-block text-center">
                        <button
                          className="btn btn-primary account-btn"
                          type="submit"
                        >
                          Reset Password
                        </button>
                      </div>
                      <div className="account-footer">
                        <p>
                          Remember your password? <Link to="/">Login</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPassword;
